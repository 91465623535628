<script setup lang="ts">
import type { CmsSectionDefault } from '@shopware-pwa/composables-next';
import { useCmsSection } from '~/composables/useCmsSection';

const props = defineProps<{
    content: CmsSectionDefault;
}>();

const { sectionClasses } = useCmsSection(props.content);
</script>

<template>
    <div
        class="cms-section-default"
        :class="sectionClasses"
    >
        <CmsGenericBlock
            v-for="cmsBlock in content.blocks"
            :key="`${cmsBlock.sectionId}-${cmsBlock.position}`"
            :content="cmsBlock"
        />
    </div>
</template>
